<template>
  <div class="historyChart">
    <Echart :options="options" id="historyChart" height="480px" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echartAdmin';
import { circleData } from "@/assets/js/api";
export default {
  data() {
    return {
      options: {},
      switchStatusData: this.cdata,
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata1: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    switchStatus: function () {
      return this.lineId // 直接监听props里的status状态
    }
  },
  watch: {
    cdata1: {
      handler() {
        console.log("11111", this.cdata)
        let bgColor = "";
        let color = [
          "#0090FF",
          "#36CE9E",
          "#FFC005",
          "#FF515A",
          "#8B5CFF",
          "#00CA69"
        ];
        //            let echartData = [{
        //                name: "1",
        //                value1: 20,
        //                value2: 20
        //            },
        //            {
        //                name: "2",
        //                value1: 20,
        //                value2: 20
        //            },
        //            {
        //                name: "3",
        //                value1: 20,
        //                value2: 20
        //            },
        //            {
        //                name: "4",
        //                value1: 20,
        //                value2: 20
        //            },
        //            {
        //                name: "5",
        //                value1: 0,
        //                value2: 0
        //            },
        //            {
        //                name: "6",
        //                value1: 0,
        //                value2: 0
        //            },
        //            {
        //                name: "7",
        //                value1: 0,
        //                value2: 0
        //            },
        //            {
        //                name: "8",
        // value1: 0,
        // value2: 0
        //            }];
        let xAxisData = [];  //echartData.map(v => v.name);
        this.cdata1.xlist.forEach((item, index) => {
            xAxisData.push(item.slice(0, 13))
        })
        let yAxisData1 = this.cdata1.ylist1;  //echartData.map(v => v.value1);
        let yAxisData2 = this.cdata1.ylist2;  //echartData.map(v => v.value2);
        let yAxisData3 = this.cdata1.ylist3;  //echartData.map(v => v.value1);
        const hexToRgba = (hex, opacity) => {
          let rgbaColor = "";
          let reg = /^#[\da-f]{6}$/i;
          if (reg.test(hex)) {
            rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt("0x" + hex.slice(3, 5))},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
          }
          return rgbaColor;
        }
        this.options = {
          backgroundColor: bgColor,
          color: color,
          legend: {
            right: 100,
            top: 10,
            textStyle: {
              color: 'rgb(255,255,255,0.9)',
              fontSize: 13,
            }
          },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              let html = '';
              params.forEach(v => {
                // console.log(v)
                html += `<div style="color: #666;font-size: 14px;line-height: 24px"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                                ${v.seriesName}: <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px">${v.value}</span>
                                GJ`;
              })
              return html
            },
            extraCssText: 'background: white; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
            axisPointer: {
              type: 'shadow',
              shadowStyle: {
                color: '#102951',
                shadowColor: 'rgba(225,225,225,1)',
                shadowBlur: 5
              }
            }
          },
          grid: {
            top: 90,
            containLabel: true
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: '{value}点',
              interval: 2,
              textStyle: {
                color: "white"
              }
            },
            axisLine: {
              lineStyle: {
                color: "white"
              }
            },
            data: xAxisData
          }],
          yAxis: [{
            type: "value",
            name: '单位：GJ',
            axisLabel: {
              textStyle: {
                color: "white"
              }
            },
            nameTextStyle: {
              color: "white",
              fontSize: 13,
              lineHeight: 40
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "white"
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }],
          series: [{
            name: "负荷预测",
            type: "line",
            smooth: true,
            // showSymbol: false,/
            symbolSize: 8,
            zlevel: 3,
            lineStyle: {
              normal: {
                color: color[0],
                shadowBlur: 3,
                shadowColor: hexToRgba(color[0], 0.5),
                shadowOffsetY: 8
              }
            },
            // areaStyle: {
            //     normal: {
            //         color: new echarts.graphic.LinearGradient( 0, 0, 0, 1,[{
            //             offset: 0,
            //             color: hexToRgba(color[0], 0.3)
            //         },
            //         {
            //             offset: 1,
            //             color: hexToRgba(color[0], 0.1)
            //         }],
            //         false),
            //         shadowColor: hexToRgba(color[0], 0.1),
            //         shadowBlur: 10
            //     }
            // },
            data: yAxisData1
          },
            // {
            //     name: "预测",
            //     type: "line",
            //     smooth: true,
            //     // showSymbol: false,
            //     symbolSize: 8,
            //     zlevel: 3,
            //     lineStyle: {
            //         normal: {
            //             color: color[1],
            //             shadowBlur: 3,
            //             shadowColor: hexToRgba(color[1], 0.5),
            //             shadowOffsetY: 8
            //         }
            //     },
            //     // areaStyle: {
            //     //     normal: {
            //     //         color: new echarts.graphic.LinearGradient( 0, 0, 0, 1, [{
            //     //             offset: 0,
            //     //             color: hexToRgba(color[1], 0.3)
            //     //         },
            //     //         {
            //     //             offset: 1,
            //     //             color: hexToRgba(color[1], 0.1)
            //     //         }],
            //     //         false),
            //     //         shadowColor: hexToRgba(color[1], 0.1),
            //     //         shadowBlur: 10
            //     //     }
            //     // },
            //     data: yAxisData2
            // }, 
            // {
            //     name: "模拟预测",
            //     type: "line",
            //     smooth: true,
            //     // showSymbol: false,
            //     symbolSize: 8,
            //     zlevel: 3,
            //     lineStyle: {
            //         normal: {
            //             color: color[2],
            //             shadowBlur: 3,
            //             shadowColor: hexToRgba(color[1], 0.5),
            //             shadowOffsetY: 8
            //         }
            //     },
            //     data: yAxisData3
            // }
          ]
        };
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    getData() {
      circleData({ machineId: this.id }).then(({ data }) => {
        console.log("data", data)
        // this.tableData = data.list
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.historyChart {
  //background: rgb(244, 243, 245);
  padding: 10px;
  width: 100%;
  // float: left;
}
</style>
